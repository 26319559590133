import React from "react";

import SEO from "../components/seo";
import Layout from "../components/layout";

const Success = () => (
  <Layout>
    <SEO title="Compute Software - Form Submitted" />
    <section className="success sub-general-content">
	    <div className="container">
	      <div className="row">
	        <div className="col-lg-12">
	        	<h1>Thank you for getting in touch</h1>
        		<p>Someone from Compute Software will be contacting you shortly.</p>
	        </div>
	      </div>
	    </div>
	</section>
  </Layout>
);

export default Success;
